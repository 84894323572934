/* ----- CSS ----- */

// Project CSS
require('@fortawesome/fontawesome-free/css/all.min.css');
require ('swiper/swiper-bundle.css');
require('nouislider/dist/nouislider.css');
require('../css/site.css');
import './../scripts/colorbox/colorbox.css';

/* ----- Javascript ----- */

const $ = require('jquery');
global.$ = global.jQuery = $;
require('jquery-ui');
require('jquery-sticky');
require ('swiper');
require('jquery-zoom');
// Project JS
import './../scripts/colorbox/jquery.colorbox.js'
import './site.js';